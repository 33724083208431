// Import Bootstrap functions and variables
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Define custom breakpoints
$custom-breakpoints: (
  "xxl": 1400px,
  "xxxl": 1600px
);

// Extend Bootstrap's default grid breakpoints
$grid-breakpoints: map-merge($grid-breakpoints, $custom-breakpoints);

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Check for the custom breakpoint
    @if $breakpoint == 'xxl' or $breakpoint == 'xxxl' {
        // Wrap the column definitions in a media query
        @include media-breakpoint-up($breakpoint) {
            @for $i from 1 through $grid-columns {
                .col#{$infix}-#{$i} {
                    @include make-col($i, $grid-columns);
                }
            }
        }
    }
}