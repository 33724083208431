@import "../../../../../Sass/variables";

.mediumModalWidth {
    max-width: 600px;
}

.modal-header-block{
    border-bottom: 1px solid red
}

