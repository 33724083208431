@import "../../../../Sass/mixins";
@import "../../../../Sass/colors";

#sidebar-fixed {
  z-index: 200;
  width: 70px;
  @include set-position(fixed, 0, 0, 0, 0);
  @include set-transition(all 0.5s);
  box-shadow: rgba(0, 0, 0, 0.15) 0 0px 12px;
  z-index: 99;

  .sidebar-block {

    .sidebar-global {

      .header-block {
        -webkit-box-align: center;
        flex-direction: column;
        -webkit-box-pack: justify;
        @include set-flex($justify-content: center, $align-items: center);
        width: 100%;
        height: 47px;

        .sidebar-logo {
          width: 32px;
          height: auto;
          // margin-bottom: 16px;
          // margin-top: 16px;
        }

        .sidebar-nav-link {
          box-sizing: border-box;
          height: 100%;
          min-width: 268px;
          max-width: 268px;
          word-break: break-word;
          z-index: 999;
          overflow-x: hidden;
          width: 100%;
          background-color: $pure-white;
          color: rgb(66, 82, 110);
          animation-duration: 0.22s;
          animation-fill-mode: forwards;
          animation-timing-function: cubic-bezier(0.2, 0, 0, 1);
          transition-property: boxShadow, transform;
          transition-duration: 0.22s;
          transition-timing-function: cubic-bezier(0.2, 0, 0, 1);

          .sidebar-title {
            box-sizing: border-box;
            padding: 32px 15px 15px;
            text-align: left;
            border-bottom: 1px solid rgba(194, 194, 194, 0.4);

            .m-toggle {
              display: none;
            }

            .title {
              margin: 0 auto;
              display: block;
            }
          }

          .sidebar-nav-item {
            padding-left: 0px;
            margin-top: 20px;

            li {

              a,
              button {
                svg {
                  width: 21px;
                  height: 21px;
                  margin-right: 10px;

                  path {
                    fill: $text-primary;
                  }
                }

                span {
                  transition: all 0.3s ease-out;
                  -webkit-transition: all 0.3s ease-out;
                  -moz-transition: all 0.3s ease-out;
                  -o-transition: all 0.3s ease-out;
                  -ms-transition: all 0.3s ease-out;
                }
              }

              &.active {

                a,
                .toggle-btn {

                  background: $primary-color;
                  border-radius: 6px;
                  color: $pure-white;

                  svg {
                    margin-right: 10px;

                    path {
                      fill: $pure-white;
                    }
                  }


                }

                :hover {
                  span {
                    color: $pure-white;
                  }

                  svg {
                    path {
                      fill: $pure-white;
                    }
                  }
                }
              }

              :hover {
                span {
                  transform: translate(0.2em, 0);
                  -webkit-transform: translate(0.2em, 0);
                  -moz-transform: translate(0.2em, 0);
                  -o-transform: translate(0.2em, 0);
                  -ms-transform: translate(0.2em, 0);
                  color: $pure-black;
                }

                svg {
                  path {
                    fill: $pure-black;
                  }
                }
              }

              a,
              button {
                img {
                  width: 20px;
                  height: auto;
                }
              }

              .sub-menu {
                li {
                  &.active>a {
                    color: $primary-color-dark;
                    background: transparent;

                    svg {
                      display: inline-block;
                      width: 12px;
                      height: 12px;
                      margin-left: 5px;

                      path {
                        fill: $primary-color;
                      }
                    }
                  }

                  & a {
                    margin-left: 35px;
                    padding-left: 30px;
                    color: $pure-black;
                    background: transparent;

                    svg {
                      display: none;
                    }

                    &:hover {
                      color: $primary-color;
                      background: transparent;
                      @include set-transition(all 0.5s);
                    }
                  }
                }
              }

              a,
              .toggle-btn {
                color: $text-primary;
                position: relative;
                display: block;
                margin: 15px;
                padding: 10px 15px;
                @include set-transition(all 0.5s);
                font-size: 14px;
                font-weight: 400;
              }

              .toggle-btn {
                background-color: transparent;
                border: 0px;
                cursor: pointer;
              }

              button:focus {
                outline: 0px auto;
              }
            }
          }
        }
      }

      .bottom-block {
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between;
        width: 100%;
        padding-top: 8px;

        .bottom-link {
          width: 50px;
          height: 50px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;

          &.active {
            background: $primary-color-dark;
          }

          &:hover {
            background: $primary-color-dark;
          }
        }
      }
    }



    #sidebar-nav {
      height: 100%;
      position: relative;
      @include set-transition(all 0.5s);

      &.active {
        margin-left: -268px;
        @include set-transition(all 0.5s);
      }


    }
  }

  &.active {
    left: 0;
  }
}


@media (max-width: 991px) {

  #sidebar-fixed {
    .mobile-nav {
      display: block;
      min-width: 0px;
      width: 0;
    }

    .sidebar-block.collapsed {
      #sidebar-nav {
        &.active {
          margin-left: 0;
        }

        .sidebar-nav-link {
          .sidebar-title {
            .m-toggle {
              display: block;
            }
          }
        }
      }
    }

    &.active {
      left: 0;

      .mobile-nav {
        width: 270px;
      }
    }

    .sidebar-block.mobile-nav .sidebar-global .header-block span .sidebar-logo {
      margin-bottom: 0px;
      margin-top: 30px;
    }
  }

  #sidebar-fixed {
    width: 0;
  }

  .overlay {
    position: fixed;
    z-index: 20;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    display: block;
  }

  .mobile-btn {
    display: block;
    margin-bottom: 30px;
  }
}

/* Screen Size - 425px */
@media (max-width: 425px) {
  #sidebar-fixed .mobile-nav .pro-sidebar-inner .sidebar-global {
    width: 100%;
  }

  #sidebar-fixed .sidebar-block .sidebar-global {
    width: 100%;
  }

  #sidebar-fixed .sidebar-block #sidebar-nav .sidebar-nav-link {
    min-width: 240px;

  }
}