@import "../../../../Sass/variables";

// Stip Fixed on scroll
.fixedTop_strip {
    position: fixed;
    width: calc(100% - 72px);
    right: 0;
    display: block;
    top: 0;
    z-index: 99;

    // Stip wrapper
    .notify_strip_wrapper {
        @include set-padding-all($padding: 12px);
        text-align: center;
        @include set-dimensions($height: 48px);
        @include set-flex($justify-content: center);

        label {
            @include set-margin($bottom: 0px, $right: 8px, $left: 8px);
            span {
                @include set-margin($right: 4px);
                svg {
                    @include set-box-size($width: auto, $height: auto);
                    path {
                        fill: $white;
                    }
                }
            }
        }

        .message_text {
            @include set-font($weight: 400, $size: 14px, $color: $white);
        }

        .contact_text,
        .contact_link {
            @include set-font($weight: 600, $size: 14px, $color: $white);
        }

        a {
            text-decoration: underline;
            line-height: 16.41px;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

// Stip backgtround color
.primary_bg {
    @include set-background($color: $primary-color);
}