@import '../../../Sass/variables';

.tab_container {
  @include set-margin($bottom: 4px);
  background-color: $pure-white;
  border-radius: 4px 4px 0px 0px;
  @include set-padding($top: 4px, $left: 12px, $right: 12px, $bottom: 4px);

  .tab_list_container {
    @include set-padding($top: 12px, $right: 24px, $left: 0px, $bottom: 0px);
    margin-bottom: 0px;
  }

  .stream_setting {
    color: $primary-color;

    svg {
      path {
        fill: $primary-color
      }
    }
  }
}