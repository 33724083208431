@import "../../../Sass/variables";

.log_wrapper {
  display: inline-flex;

  .log_content {
    white-space: normal;
    @include adjust-text($line-height: 1.3);
  }

  .log_tag {
    @include set-font($size: 10px);
    @include adjust-text($letter-spacing: 0.5px, $transform: uppercase);
  }
}

.infiniteScroll {
  height: 100%;
  max-height: calc(100vh - 170px);
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #ffffff;
    background-color: $gray-dark;
  }

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
    background-color: $pure-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $text-tertiary;
    border-radius: 50px;
  }
}

.tasklogsModal {
  height: 100%;
  overflow: hidden !important;
}

.btnOutline {
  @include set-background($color: $pure-white);
  @include set-border-all($border: 1px solid, $color: $primary-color, $radius: 4px);
  @include set-padding-all($padding: 10px 16px);
  @include set-font($color: $primary-color, $size: 14px);
  @include set-margin($right: 42px);
  span {
    @include set-margin($right: 4px);
    path {
      @include set-font($color: $primary-color, $size: 14px);
      fill: $primary-color;
    }
  };
  &:hover {
    @include set-font($color: $primary-color, $size: 14px);
    @include set-background($color: $off-white);
  }
}

.limitMessage {
  @include set-margin($bottom: 25px, $top: -25px);
}
