@import "../../../../Sass/colors";

@import "../../../../Sass/fonts";
.verify-code{
	a{
		text-decoration: underline;
	}
}
.digit-group {
	input {
		width: 40px;
		height: 50px;
		background-color: $gray;
        border: none;
        border-radius: 6px;
		line-height: 50px;
		text-align: center;
		font-size: 24px;
		font-weight: 200;
		color: $pure-black;
		margin: 0 10px 30px;
    }
    input:first-child {
		margin-left: 0;
    }

	.splitter {
		padding: 0 5px;
		color: white;
		font-size: 24px;
	}
}

