@import "../../../../Sass/variables";

.tab_container {
    @include set-margin($bottom: 16px);
    @include set-padding($top: 16px, $bottom: 0px, $right: 16px, $left: 16px);
    @include set-flex($justify-content: space-between);


    ul {
        li {
            padding-bottom: 4px;
            @include set-padding($top: 0px, $bottom: 8px, $right: 8px, $left: 8px);
            border-bottom: 3px solid transparent;
            border-radius: 0px;
            @include set-margin($right: 24px);

            a {
                font-weight: 500;
                color: $text-other;
            }

            &.active_List {
                border-color: $primary-color;

                a,
                span {
                    font-weight: 500;
                    color: $primary-color;
                }
            }

            &:hover {
                a {
                    font-weight: 500;
                    color: $primary-color
                }
            }

            &:last-child {
                @include set-margin($right: 0);
            }
        }

    }
}

.tab_container_extra {
    width: 80%;
    position: relative;
    padding-left: 0px;

    &:after {
        content: "";
        position: absolute;
        width: 96%;
        height: 1px;
        background-color: #CED4DA;
        bottom: -1px;

    }
}