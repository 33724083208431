// @import "./colors";
// @import "./fonts";
// @import "./mixins";

// -----------------------------------------------------------------------------
// This file contains all default rule styles
// -----------------------------------------------------------------------------


/*================================
    Text Transform
  ==================================*/

.text-upper {
  text-transform: uppercase;
}

.text-lower {
  text-transform: lowercase;
}

.text-capital {
  text-transform: capitalize;
}

.underline-link {
  text-decoration: underline;
}

/*================================
    Box Shadow
  ==================================*/

//   * No shadow *
.box-shadow-none {
  box-shadow: none;
}

/*================================
    Equal Box Height
  ==================================*/
//   * To use : Wrap it around <div> *
.panelBlock-fh {
  height: calc(100% - 0.5rem);
}

//   * To use : Wrap it around <div> *
.panel-fh {
  height: calc(100% - 1.5rem);
}

// *To use: Wrap it around <div> *
.no-panel-fh {
  height: auto;
}

/*================================
    Cursors
  ==================================*/

.cursor-pointer {
  cursor: pointer !important;
}

//   * No cursor *
.cursor-default {
  cursor: default !important;
}

/*================================
    Overflow
  ==================================*/

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-none {
  overflow: unset;
}

/*================================
    Visibility
  ==================================*/

.visible-show {
  visibility: visible;
}

.visible-hidden {
  visibility: hidden;
}

/*================================
    Border
  ==================================*/
// Border Radius
.border-radius-0 {
  border-radius: 0px;
}

// Remove Border
.no-border {
  border: 0px;
}

// Bottom Border
.bottom-border {
  border: 0;
  border-radius: 0px;
  border-bottom: 2px solid $text-tertiary;
}

//Table no border

.no-border {

  .td,
  .th {
    border: 0px;
  }
}

/*================================
    Border Radius
  ==================================*/

//   * Circle *
.border-radius-circle {
  border-radius: 50px;
}

//   * Curve *
.border-radius-default {
  border-radius: 4px;
}

//   * Square *
.border-radius-none {
  border-radius: 0;
}

.underline-link {
  text-decoration: underline;
}

/*================================
    Custom Scrollbar
  ==================================*/
.scroll-height {
  max-height: 550px;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 0px !important;
}

//   * Vertical Scrollbar *
.vertical-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $pure-white;
    background-color: $gray-dark;
    visibility: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $text-tertiary;
    border-radius: 50px;
    visibility: hidden;
  }

  &.visible-scroll {
    &::-webkit-scrollbar-track {
      visibility: visible;
    }

    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }

  &.on-hover-scroll:hover {
    &::-webkit-scrollbar-track {
      visibility: visible;
    }

    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

//   * Horizontal Scrollbar *

/*================================
    Elements Position
  ==================================*/

// Aligned center (Middle)
.p-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  overflow: hidden;
}

/*================================
    Text Overflow Break
  ==================================*/

// block ellipsis
// (Use it on parent element / Define Height)
.block-ellipsis {
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*================================
    Show icon on hover
  ==================================*/

.hover-content {
  @include set-flex($justify-content: flex-start);

  .hover-icon {
    opacity: 0;
    visibility: hidden;
    margin-left: 5px;
  }

  &:hover {
    color: $primary-color !important;

    .hover-icon {
      svg {
        width: 16px;
        height: auto;

        path {
          fill: $primary-color;
          fill-opacity: 1;
        }
      }

      opacity: 1;
      visibility: visible;
    }
  }
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: $primary-extra-light;
}

/*================================
    React Select Style Rules
  ==================================*/

// * Default width for React-Select *
// .react-select {
//   width: 150px;
// }

// * React Select main child style *
.react-select>div {
  background-color: $pure-white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  border-color: $gray-dark !important;
}

// * React Select Value style *
.react-select [class$="-singleValue"] {
  color: $text-primary;
  @include set-margin($right: 2px, $left: 2px);
}

// * React Select Label icon style *
.react-select {
  svg path {
    fill: $text-primary;
  }
}

// * Checklist Config Modal *
.select-sm-transparent {
  width: 100px;
  margin-right: 2px;

  .css-ao6u52-control {
    border-color: transparent;
    border-right: 0;
    border-bottom: 2px solid $light-color;

    &:hover {
      border-color: transparent;
      border-bottom: 2px solid $light-color;
    }

    .css-1okebmr-indicatorSeparator {
      background-color: transparent;
    }
  }
}

/*================================
+    Flex wrapper
+  ==================================*/

.block-wrapper--flex {
  @include set-flex($justify-content: center);

  .block-flex--icon {
    @include set-background($primary-extra-light);
    @include set-box-size($width: 34px, $height: 34px);
    @include set-flex($justify-content: center, $align-items: center);
    @include set-margin($right: 8px);

    svg {
      @include set-box-size($width: 16px, $height: 16px);

      path {
        fill: $primary-color;
      }
    }
  }

  .block-flex--content {
    word-wrap: break-word;
    word-break: break-all;
  }
}

/*================================
+    React DatePicker
+  ==================================*/
.react-datepicker__input-container {
  input {
    max-width: 132px;
    background-color: $pure-white;
    // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid $gray-dark;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:focus-visible {
      outline: 0px;
    }

    &.disabled {
      border-color: $gray-dark;
      background-color: $gray;
      cursor: default;
    }
  }
}

.react-datepicker-popper {
  z-index: 9999;

  .react-datepicker {
    &.highlightedCurrentDay {
      .react-datepicker__day--today {
        @include set-background($color: $primary-extra-light-focused !important);
        @include set-font($color: $text-primary);
      }
    }

    @include set-background($color: $pure-white !important);
    @include set-border($radius: 4px);
    border: 1px solid $gray;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    // box-shadow: 0px 0px 4px $color-box-shadow;
    @include set-font($color: $text-secondary,
      $weight: 400,
      $size: 12px,
      $family: $primary-font );

    .react-datepicker__month-container {
      width: min-content;
      overflow: hidden !important;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__week {
      @include set-flex($justify-content: center, $align-items: center);
      display: inline-flex;

      .react-datepicker__day--selected {
        @include set-background($color: $primary-color !important);
        @include set-font($color: $pure-white);
      }
    }

    .react-datepicker__day {
      @include set-flex($justify-content: center, $align-items: center);
      display: inline-flex;
      @include set-font($color: $text-primary,
        $weight: 300,
        $size: 12px,
        $family: $primary-font );
      @include set-dimensions($width: 35px, $height: 35px);
      @include set-border($radius: 50%);

      &:not(.react-datepicker__day--disabled):hover {
        background-color: $off-white;
      }

      &.react-datepicker__day--disabled {
        @include set-font($color: $gray-dark);
      }
    }

    .react-datepicker__time-container {
      @include set-dimensions($width: 120px !important);

      .react-datepicker__time .react-datepicker__time-box {

        @include set-dimensions($width: 100%);
        @include set-padding($top: 8px);
        @include set-background($pure-white);

        .react-datepicker__time-list {
          @include set-dimensions($min-height: 238px);

          .react-datepicker__time-list-item {
            &.react-datepicker__time-list-item--disabled {
              @include set-font($color: $gray-dark);
            }

            &:not(.react-datepicker__time-list-item--disabled):not(.react-datepicker__time-list-item--selected):hover {
              background-color: $off-white;
            }

            @include set-flex($justify-content: flex-start,
              $align-items: center);
            @include set-margin($top: 0, $left: 8px, $right: 3px, $bottom: 0);
            @include set-padding($top: 8px,
              $right: 12px,
              $bottom: 8px,
              $left: 12px);
            @include set-font($color: $text-primary,
              $weight: 300,
              $size: 12px,
              $family: $primary-font );
            @include set-border($radius: 2px);
            @include set-dimensions($height: 32px);

            &.react-datepicker__time-list-item--selected {
              @include set-background($color: $primary-color);
              @include set-font($color: $pure-white);
            }
          }

          @extend .vertical-scroll;

          &::-webkit-scrollbar-track {
            visibility: visible;
          }

          &::-webkit-scrollbar-thumb {
            visibility: visible;
          }

          &:hover {
            &::-webkit-scrollbar-track {
              visibility: visible;
            }

            &::-webkit-scrollbar-thumb {
              visibility: visible;
            }
          }
        }
      }
    }

    .react-datepicker__header {
      @include set-background($color: inherit);
      @include set-padding-all(0);
      @include set-background($color: $off-white);
      border: none;
      overflow: hidden;

      .react-datepicker__current-month,
      .react-datepicker-time__header {
        @include set-background($color: $off-white !important);
        @include set-padding-all(12px);
        @include set-font($color: $text-secondary,
          $weight: 500,
          $size: 14px,
          $family: $primary-font );
      }

      .react-datepicker__day-names {
        .react-datepicker__day-name {
          @include set-flex($justify-content: center, $align-items: center);
          display: inline-flex;
          @include set-dimensions($width: 35px, $height: 35px);
          @include set-font($color: $text-primary, $weight: 500, $size: 12px);
        }
      }
    }

    .react-datepicker__month {
      @include set-margin($left: 12px, $right: 12px, $bottom: 12px);
    }

    .react-datepicker__navigation {
      top: 3%;
      @include set-dimensions($width: 24px, $height: 24px);
      @include set-border($radius: 4px);
      @include set-background($color: $gray);
      @include set-margin($top: 0, $left: 9px, $right: 9px, $bottom: 0);
      @include set-flex($justify-content: center, $align-items: center);
      display: inline-flex;

      .react-datepicker__navigation-icon {
        top: -4px;
        @include set-font($size: 10px);
      }

      .react-datepicker__navigation-icon--previous {
        right: 1px;
      }

      .react-datepicker__navigation-icon--next {
        left: 1px;
      }
    }
  }
}

.filter_wrapper {
  display: flex;
  flex: 1;
}

/*================================
+    React Tabs
+  ==================================*/
// Tabs Pills
.tabs-wrapper {
  .react-tabs__tab-list {
    border-bottom: 0px solid transparent;
    margin: 0 0 10px;
    padding: 0;

    .react-tabs__tab {
      display: inline-block;
      border: 0px solid transparent;
      border-bottom: none;
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 4px 8px;
      background: $gray;
      margin-right: 8px;
      cursor: pointer;
      border-radius: 4px;

      &:last-child {
        margin-right: 0px;
      }
    }

    .react-tabs__tab--selected {
      background: $primary-color !important;
      border-color: transparent !important;
      color: $pure-white;
    }
  }
}

/*================================
+    Active Border
+  ==================================*/
.borderSelect {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 2px;

  &:hover {
    border-color: $primary-color-dark;
  }

  &.active {
    border-color: $primary-color-dark;
  }
}

// Floating Menu
.menu_none {
  display: none;
}

.menu_display {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  display: block;
  transition: all 0.6s;
}

.floating_position__left_side {
  li {
    button {
      span {
        svg {
          path {
            fill: $text-primary;
          }
        }
      }
    }

    &:hover {
      span {
        svg {
          path {
            fill: $primary-color;
          }
        }
      }
    }
  }
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd !important;
  cursor: none;

  span {
    svg {
      path {
        fill: #adb5bd !important;
      }
    }
  }
}

/*================================
+    Line Clamp
+  ==================================*/

.line_clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.password_input {
  input {
    @include set-padding($right: 30px);
  }
}

/*================================
+    Icon colors
+  ==================================*/

// // Task Type Icons
// .icon-color-default {
//   display: flex;
//   align-items: center;

//   .icon-size {
//     svg {
//       width: auto;
//       height: 12px;
//     }
//   }

//   svg {
//     path {
//       fill: $primary-color;
//     }
//   }

//   &:hover {
//     svg {
//       path {
//         fill: $pure-white;
//       }
//     }
//   }
// }