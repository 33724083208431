// -----------------------------------------------------------------------------
// This file contains all @colors declarations.
// -----------------------------------------------------------------------------
$primary-color: var(--primary-color);
$primary-color-dark: var(--primary-color-dark);
$primary-color-light: var(--primary-color-light);
$primary-color-background: var(--primary-color-background);


// Main color varialbels
// $primary-color-dark: #194DDE;
// $primary-color-light: #6588EB;
// $primary-color: #3766E8;

$black:#000000;
$white:#ffffff;

$color-scheme: light;
$pure-black:#000000;
$pure-white:#ffffff;

$second-color:#78D5D7;
$off-white:#F9F9F9;

$heading-primary:#212121;
$text-secondary: #343434;
$text-primary:#545454;
$text-other: #7E7E7E;
$text-tertiary:#A0A0A0;
$text-highlight:#004156;

$gray: #F0F0F0;
$gray-dark:#CED4DA;
$gray-light:#CACBCE;

$form-bg-color:#EFF3FD;
$primary-extra-light:#F3F6FE;
$primary-extra-light-focused:#E0E8FC;
$primary-white-medium-shade:#EBF0FD;
$search-field-background: #F3F3F4;
$light-bg-color:#E6ECFC;


// $color-scheme: dark;
 
// $pure-white:#28292C;
// $off-white:#1F2023;
 
// $pure-black: #ffffff; // Inverted
// // $pure-white: #000000; // Inverted
 
// $second-color: #62a2a4; // Darker shade of $second-color
// // $off-white: #2a2a2a; // Darker shade for backgrounds
 
// $heading-primary: $gray-dark; // Lighter shade for visibility in dark
// $text-secondary: #c8c8c8; // Lighter shade for secondary text
// $text-primary: #dadada; // Lighter shade for primary text
// $text-other: #a3a3a3; // Adjusted for readability
// $text-tertiary: #b0b0b0; // Adjusted for tertiary text
// $text-highlight: #00707b; // Adjusted highlight color
 
// $gray: #202020; // Darker shade for gray
// $gray-dark: #313639; // Adjusted for dark theme
// $gray-light: #3a3b3d; // Adjusted for dark theme
 
// $form-bg-color: #2d324f; // Darker shade for form backgrounds
// $primary-extra-light: #2f3350; // Darker shade for primary extra light
// $primary-extra-light-focused: #3a4268; // Darker and more saturated
// $primary-white-medium-shade: #3c4370; // Adjusted for dark theme
// $search-field-background: #2E2E2E;
// $light-bg-color: #2f3350;
 
// $gray: #3c3c3c;
// $gray-dark: #535353;


$brand-orange:#FF7E17;
$brand-pink:#FF879D;
$brand-purple:#9077F7;

$green-success: #029262;
$yellow-warning: #FFC107;
$yellow-warning-dark: #B54708;
$red-failure:#DC3545;
$red-failure-dark:#C90e20;
$color-sky: #00a7ab;

//Define lighter shade for background colors
$brand-orange-background: rgba($brand-orange, 0.15);
$brand-pink-background: rgba($brand-pink, 0.15);
$brand-purple-background: rgba($brand-purple, 0.15);

$green-success-background: rgba($green-success, 0.15);
$yellow-warning-dark-background: rgba($yellow-warning-dark, 0.15);
$yellow-warning-background: rgba($yellow-warning, 0.15);
$red-failure-background: rgba($red-failure, 0.15);
$red-failure-dark-background: rgba($red-failure-dark, 0.15);
$color-sky-background: rgba($color-sky, 0.15);

$color-box-shadow: rgba($off-white, 0.9);




// Tags
$tag-default-bg: $primary-extra-light;
$tag-warning-bg:#F8D7DA;
$tag-success-bg:#CCE9E0;
$tag-orange-bg:#FFEFE2;
$tag-purple-bg: #E9E4FD;

$tag-default-bg-primary: $primary-color;
$tag-warning-bg-primary: $red-failure;
$tag-success-bg-primary: $green-success;
$tag-orange-bg-primary: $brand-orange;
$tag-purple-bg-primary: $brand-purple;

// Extra
$btn-disabled-color:#6588eb;
$light-color: #cccccc;
$auth-bg: #E5E5E5;
$tag-color: #D1E3FF;
$video-bg: #474646;
