@import "../../../../Sass/variables";

.breadcrumb {
  @include set-padding-all(0px);
  @include set-margin($bottom: 4px);
  background: transparent !important;

  li {
    @include set-font($weight: 400, $size: 12px);

    a:hover {
      text-decoration: none;
    }

    &::after {
      @include set-padding($left: 8px);
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 24 24'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z' fill='gray'/></svg>");
    }

    &:last-child::after {
      content: none;
    }

    svg {
      @include set-dimensions($width: 12px, $height: auto);

      path {
        fill: #7c7272;
      }
    }

    a.muted-link {
      color: #7c7272;
      cursor: pointer;
    }

    &:first-child a {
      cursor: default;
    }
  }
}

.header-title {
  .header-primary {
    @include set-margin($bottom: 4px);
  }
}

.crumb-cursor-type {
  .breadcrumb :nth-child(2) {
    .muted-link {
      cursor: default !important;

    }
  }
}

.breadcrum-link {
  cursor: pointer !important;
  color: $text-other !important;
}

.no-breadcrum-link {
  cursor: default !important;
  color: $text-other !important;
}