@import "../../../Sass/colors";
@import "../../../Sass/fonts";

.loader-body {
  background-color: $pure-white !important;
}

.loaderLabel {
  font-family: $primary-font;
}

.page-loader {
  height: 32px;
  width: 32px;
  -webkit-animation: loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes page-loader-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes page-loader-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.page-loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  text-align: center;
}

.page-loader::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  margin: auto;
  width: 8px;
  height: 8px;
  background: $primary-color;
  border-radius: 50%;
  -webkit-animation: loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes page-loader-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(24px, 0, 0) scale(.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes page-loader-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(24px, 0, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.page-loader::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: $primary-color;
  border-radius: 50%;
  -webkit-animation: page-loader-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: page-loader-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes page-loader-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(-24px, 0, 0) scale(.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes page-loader-3 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(-24px, 0, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.page-loader span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
}

.page-loader span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: $primary-color;
  border-radius: 50%;
  -webkit-animation: page-loader-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: page-loader-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes page-loader-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, 24px, 0) scale(.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes page-loader-4 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, 24px, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.page-loader span::after {
  content: "";
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: $primary-color;
  border-radius: 50%;
  -webkit-animation: loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes page-loader-5 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, -24px, 0) scale(.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes page-loader-5 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, -24px, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}




.loader { 
  width: 200px;
  margin: 0 auto;
  border-radius: 25px;
  border: 0px solid transparent;
  position: relative;
  padding: 1px;
}
.loader:before {
  content:'';
  border:1px solid transparent; 
  border-radius:10px;
  position:absolute;
  top:0px; 
  right:0px; 
  bottom:-4px; 
  left:0px;
  background-color: #f2f2f2;
}
.loader .loaderBar { 
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: $primary-color;
    width: 100%;
    height: 6px;
    animation: borealisBar 0.8s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}