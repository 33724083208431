@import "../../../Sass/variables";

.auth-login-layout{
    width: 50%;
    min-width: 50%;
}

.auth-login{
    // @include set-flex($direction: column);
    @include set-dimensions($width: 450px);
}


.sso-line {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        margin-left: 20px;
        margin-right: 20px;
    }
    .line {
        width: 52px;
        height: 1px;
        background-color: $gray-dark;
      }
}

  @media (max-width: 768px) {
      .auth-login {
          @include set-dimensions($width: auto);
      }
  }