@import "../../../../Sass/variables";

.tag-block {
  @include set-border($radius: 2px);
  @include set-dimensions($width: fit-content, $height: auto);
  @include set-margin($right: 4px);
  @include set-font($size: 12px, $weight: 500);
  display: inline-block;
  line-height: 14.52px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.tag-wrapper {
  @include set-flex($justify-content: space-between, $align-items: center);
  @include set-padding($top: 0px, $bottom: 0px, $left: 8px, $right: 8px);

  button.btn-label {
    @include set-font($size: 12px, $weight: 400);
    @include set-margin($right: 8px);
  }

  &:hover {
    button {
      span {
        svg {
          path {
            fill: $pure-white;
          }
        }
      }
    }
  }

  button.btn-close {
    width: 20px;
    background: transparent;
    border: 0px;
    border-radius: 0px;
    transition: none;

    span {
      svg {
        @include set-dimensions($width: 9px, $height: 9px);

        path {
          fill: $pure-black;
        }
      }
    }

    &:hover {
      background: $red-failure;
    }
  }
}

.tag-full-width {
  @include set-dimensions($width: 100%);
}

// Tag Colors
.tag-default-light {
  background-color: rgba($second-color, 0.2);
  color: $second-color;

  &:hover {
    background-color: rgba($second-color, 0.6);
    color: $pure-white;
  }
}

.tag-default {
  background-color: $primary-color-background;
  color: $primary-color;

  &:hover {
    background-color: $primary-color;
    color: $pure-white;
  }
}

.tag-primary {
  background-color: $primary-color;
  color: $pure-white;
}

.tag-success {
  background-color: $green-success-background;
  color: $green-success;

  &:hover {
    background-color: $green-success;
    color: $pure-white;
  }
}

.tag-warning {
  background-color: $red-failure-background;
  color: $red-failure;

  &:hover {
    background-color: $red-failure;
    color: $pure-white;
  }
}

.tag-orange {
  background-color: $brand-orange-background;
  color: $brand-orange;

  &:hover {
    background-color: $brand-orange;
    color: $pure-white;
  }
}

.tag-yellow {
  background-color: $yellow-warning-background;
  color: $yellow-warning-dark;

  &:hover {
    background-color: $yellow-warning;
    color: $pure-white;
  }
  
}

.tag-purple {
  background-color: $brand-purple-background;
  color: $brand-purple;

  &:hover {
    background-color: $brand-purple;
    color: $pure-white;
  }
}

.tag-sky {
  background-color: $color-sky-background;
  color: $color-sky;

  &:hover {
    background-color: $color-sky;
    color: $pure-white;

    svg {
      path {
        fill: $pure-white;
      }
    }
  }
}

.tag-gray {
  background-color: $gray;
  color: $text-primary;
}

.tag-primary-nohover {
  background-color: rgba($primary-color-light, 0.2);
  color: $primary-color-dark;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;

    path {
      fill: $primary-color-dark;
    }
  }
}

.tag-success-solid {
  background-color: rgba($green-success, 0.8);
  color: $pure-white;

  &:hover {
    background-color: rgba($green-success, 0.8);
    color: $pure-white;
  }
}

.tag-warning-solid {
  background-color: rgba($red-failure, 0.8);
  color: $pure-white;
}



.tag-orange-solid {
  background-color: rgba($brand-orange, 0.8);
  color: $pure-white;

  &:hover {
    background-color: rgba($brand-orange, 0.8);
    color: $pure-white;
  }
}

.tag-light {
  background-color: rgba($primary-color, 0.9);
  color: $primary-color;

  &:hover {
    background-color: rgba($primary-color, 0.6);
    color: $pure-white;
  }
}

// Tag Size

.tag-lg {
  padding: 4px 12px;
}

.tag-md {
  padding: 4px 8px;
}

.tag-sm {
  @include set-padding(2px, 6px, 2px, 6px);
}

.tag-xs {
  padding: 0 8px;
}

.tag-round {
  border-radius: 25px;
}

.tag-schedule-item {
  text-align: left;
  white-space: normal;
}

.btn-textIcon.copy-link {
  padding: 0;
  text-align: left;
  p {
    display: flex;
  }

  &:hover {
    background-color: transparent;
  }
}

.copy-link {
  cursor: pointer;

  p {
    color: $primary-color;
    font-size: 12px;
    margin-bottom: 0px;

    span {
      svg {
        width: 12px;
        height: auto;

        path {
          fill: $primary-color;
        }
      }
    }

    &:hover {
      color: $primary-color-dark;

      svg {
        path {
          fill: $primary-color-dark;
        }
      }
    }
  }
}

// Task Type Icons
.icon-color-default {
  display: flex;
  align-items: center;

  .icon-size {
    svg {
      width: auto;
      height: 12px;
    }
  }

  svg {
    path {
      fill: $primary-color;
    }
  }

  &:hover {
    svg {
      path {
        fill: $pure-white;
      }
    }
  }
}