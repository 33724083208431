@import "../../../../Sass/variables";

/*================================
      TOP HEADER
  ==================================*/
.header {
  @include set-padding(8px, 32px, 8px, 32px);
  background-color: $pure-white;
}

.header-with-alert {
  padding: 10px 30px;
  background-color: $pure-white;
  margin-top: 50px;
}

.header__dropdown-logo {
  margin-right: 5px;
  top: 3px;
  right: 5px;
  cursor: pointer;

  display: flex;
  align-items: center;
}

.header__dropdown-container {
  width: 300px;
  position: absolute;
  top: 40px;
  z-index: 10;
  background: $pure-white;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &--animation {
    width: 300px;
    position: absolute;
    top: 58px;
    z-index: 10;
    background: $pure-white;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
  }
}

.arrow-icon--down {
  transform: rotate(0deg);
  transition: ease-in-out all 0.3s;
}
.arrow-icon--up {
  transform: rotate(180deg);
  transition: ease-in-out all 0.3s;
}


.menu-container {
  position: relative;
  .menu {
    background-color: $pure-white;
    border-radius: 8px;
    position: absolute;
    top: 50px;
    right: 0;
    width: 260px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    background-color: $pure-white;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      z-index: 99;
    }
  }
  .menu-trigger {
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    .user-thumb {
      width: 22px;
      height: auto;
      border-radius: 50px;
    }
  }
}

.drop-flex-wrapper {
  @include set-flex($justify-content: flex-start);
}

.drop-profile--thumbHeader {
  img {
    @include set-dimensions($width: 28px, $height: 28px);
    border-radius: 50px;
    border: 0;
    background-color: $gray;
  }
}

.drop-wrapper--profile {
  @include set-margin($top: 8px, $bottom: 8px, $right: 16px, $left: 16px);
  overflow: hidden;

  .drop-profile--thumb {
    img {
      @include set-dimensions($width: 48px, $height: 48px);
      border-radius: 50px;
      border: 2px solid #eaeaea;
      background-color: $gray;
    }
  }
  .drop-profile--content {
    @include set-margin($left: 15px);
    @include set-dimensions($width: 0);
    flex: 1;

    ul {
      @include set-margin($bottom: 0px);
      li {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 1.5;

        &:nth-child(1) {
          @include set-font($weight: 500, $color: text-primary);
        }
        &:nth-child(2),
        &:nth-child(3) {
          @include set-font($size: 12px, $color: $text-other);
        }
        &:nth-child(3) {
          text-transform: uppercase;
        }
      }
    }
  }
}

.drop-wrapper--list {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
      @include set-flex($justify-content: flex-start);
      @include set-padding($top: 12px, $bottom: 12px, $right: 16px, $left: 16px);
      @include set-font($size: 14px, $color: $text-primary);
      @include set-background($color: $pure-white);
      // @include set-margin($top: 6px, $bottom: 4px);
      cursor: pointer;
      svg {
        margin-right: 6px;
        @include set-dimensions($width: 16px, $height: 16px);
      }
      span {
        @include set-transition(all 0.1s ease-out);
      }
      svg path {
        fill: $text-primary;
      }
      &:first-child {
        cursor: default;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;
      }
      &:nth-child(2) {
        border-bottom: 1px solid $gray;
      }
      &:last-child {
        border-bottom: 0px;
        color: $text-primary;
        @include set-margin($bottom: 4px);

        svg {
          path {
            fill: $text-primary;
          }
        }
      }
      &:focus,
      &:hover {
        color: #16181b;
        text-decoration: none;
        background: $off-white;
        span {
          transform: translate(0.2em, 0);
          -webkit-transform: translate(0.2em, 0);
          -moz-transform: translate(0.2em, 0);
          -o-transform: translate(0.2em, 0);
          -ms-transform: translate(0.2em, 0);
          color: $text-primary;
        }

        &:first-child {
          // background: transparent;
          span {
            cursor: default;
            color: $text-primary;
            transform: none;
            -webkit-transform: none;
            -moz-transform: none;
            -o-transform: none;
            -ms-transform: none;
          }
        }
        &:last-child {
          // background-color: #F9F9F9;
          // background: transparent;
          span {
            border-bottom: 0px;
            color: $text-primary;
          }
          svg {
            path {
              fill: $text-primary;
            }
          }
        }
      }
    }
  }
}

 
@media (max-width: 1420px) {
  .header-with-alert {
    margin-top: 60px;
  }
}

@media (max-width: 1320px) {
  .header-with-alert {
    margin-top: 80px;
  }
}