@import '../../../../../Sass/variables';

ul.navLinksWrapper {
    @include set-margin($bottom: 0px, $right: 30px);
    @include set-flex("row", "space-between", "center");

    li {
        list-style: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #545454;

        span {
            svg {
                margin-right: 4px;
                height: 14px;
            }
        }
    }

    .company_list_button {
        border: 1px solid $gray-dark;
        @include set-flex(row, start, center);
        min-width: 120px;
        max-width: 200px;
        gap: 10px;

        span:first-child {
            svg {
                margin: 0;
            }

        }

        .nav_company_name {
            margin: 0 auto;
            min-width: 120px;
            max-width: 160px;
            text-align: start;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    .companies_arrow_down_icon {
        display: flex;
        align-self: center;
        padding-top: 2px;
        margin-bottom: 0;

        svg {
            height: 7px !important;
            width: 14px !important;
            margin-right: 0;
        }

    }

}

.boxSeparator {
    @include set-margin($bottom: 30px, $top: 30px);
    position: relative;

    span {
        font-weight: 500;
    }

    &:after,
    &:before {
        content: "";
        @include set-dimensions($width: 60px, $height: 1px);
        @include set-background($color: $gray-dark);
        @include set-margin(4px, 8px, 4px, 8px);
        display: inline-block;
    }
}

.boxSeparator,
.boxLinkContent {
    text-align: center;

    label {
        @include set-font($color: $text-primary, $weight: 400, );
    }

    button {
        padding: 0;
        color: $primary-color;
    }

    ul>li>a>img {
        width: 140px;
        height: auto;
        transform: perspective(1px) translateZ(0);
        transition-duration: 0.3s;
        transition-property: transform;
        transition-timing-function: ease-out;

        &:hover {
            transform: translateY(-5px);
        }
    }

    .boxNote {
        @include set-margin($top: 20px);
        display: block;
        font-style: italic;
    }
}



.boxWrapper {
    @include set-margin($bottom: 0px, $top: 30px);

    .companies_list {
        padding-right: 8px;

        li {
            padding: 12px;
            border-radius: 4px;
            cursor: pointer;
            background-color: $pure-white;
            @include set-flex(row, start, center);
            gap: 12px;

            &:not(.active):hover {
                background-color: $off-white;
            }

            .company_name {
                flex: 1;

            }
        }

        .active {
            background-color: $primary-extra-light-focused;
            cursor: default;
            border: 1px solid $primary-extra-light-focused;
        }
    }



}

.boxContent_form {
    margin: 0 auto;
    width: 420px;
    text-align: center;

    label {
        @include set-font($color: $text-primary, $weight: 400, );
        @include set-margin($bottom: 12px);
    }

    .form_group {
        @include set-flex($justify-content: flex-start);
    }
}

.boxContent_wrapper {
    max-width: 500px;
    @include set-margin($bottom: 20px);

    p {
        @include set-font($color: #545454, $size: 14px);
        font-weight: 400;
        line-height: 18px;
    }
}

.imageWrapper {
    @include set-margin($top: 8px);

    img {
        @include set-dimensions($width: 220px, $height: auto);
        @include set-margin(0, auto, 0, auto);
        border-radius: 4px;
    }
}