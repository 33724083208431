@import "../../../../Sass/variables";

.header-section {
    @include set-margin($bottom: 20px);

    .flex-wrapper {
        @include set-flex($justify-content: space-between, $align-items: flex-start);

        .header-block {
            max-width: 600px;
            word-break: break-word;
            @include set-margin($right: 20px);

            .header-title--skeleton {
                @include set-flex($justify-content: flex-start, $align-items: flex-end);
                @include set-margin($bottom: 15px, $top: 10px);
            }

            .sub-title {
                @include set-font($size: 14px, $weight: 400, $color: $text-primary);
                @include set-margin($bottom: 0);
            }
        }
    }

    .page-action {
        @include set-flex($justify-content: flex-start, $align-items: baseline);
    }
}