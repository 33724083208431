@import "../../../../../Sass/variables";

.help-section {
  position: absolute;
  left: 90px;
  bottom: 16px;
  padding-top: 7px;
  box-shadow: 1px 0px 4px 2px #9190902a;

  &__line {
    width: 100%;
    height: 1.5px;
    margin: 2px 0;
    background: #9190902a;
  }
  width: 260px;
  // height: 230px;
  border-radius: 10px;
  background: $pure-white;

  &__link {
    padding: 12px 32px 12px 32px;
    font-size: 14px;

    &:hover {
      @include set-background($off-white);
    }

    svg path {
      fill: $text-primary;
    }

    & > a {
      padding-left: 12px;
      color: $text-primary;
      @include set-transition(all 0.1s ease-out);

      &:hover {
        font-weight: 500;
        transform: translate(0.2em, 0);
        -webkit-transform: translate(0.2em, 0);
        -moz-transform: translate(0.2em, 0);
        -o-transform: translate(0.2em, 0);
        -ms-transform: translate(0.2em, 0);
        fill: $pure-white;
      }
    }
  }
}

.help-btn-link{
  &:hover{
    color: $primary-color;
    font-weight: 500;
  }
}