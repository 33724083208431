// @import "./colors";
// @import "./fonts";

// -----------------------------------------------------------------------------
// This file contains all default typohraphy styles
// -----------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-primary;
  margin: 0 0 8px;
  font-family: $primary-font;
  font-weight: 500;
  line-height: 1.6;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

b,
strong {
  font-weight: 500 !important;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 8px;
  color: $text-primary;
}

caption,
.caption {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.41px;
  color: $text-tertiary;

  .small {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.18px;
    color: $text-tertiary;
  }
}

label {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.41px;
  color: $text-primary;
}

.text-overflow {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

/* Font-Size */
.fs-7 {
  font-size: 7px;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-40 {
  font-size: 40px !important;
}

/* Font-weight */
.fw-200 {
  font-weight: 200;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

b,
strong {
  font-weight: bold;
}

// Max width
.mxw-50 {
  max-width: 50px;
}

.mxw-100 {
  max-width: 100px;
}

.mxw-150 {
  max-width: 150px;
}

.mxw-200 {
  max-width: 200px;
}

.mxw-250 {
  max-width: 250px;
}

.mxw-300 {
  max-width: 300px;
}

.mxw-350 {
  max-width: 350px;
}

.mxw-400 {
  max-width: 400px;
}

.mxw-450 {
  max-width: 450px;
}

.mxw-500 {
  max-width: 500px;
}

.mxw-550 {
  max-width: 550px;
}

.mxw-600 {
  max-width: 600px;
}

.mxw-650 {
  max-width: 650px;
}

.mxw-700 {
  max-width: 700px;
}

.mxw-750 {
  max-width: 750px;
}

.mxw-800 {
  max-width: 800px;
}

.mxw-850 {
  max-width: 850px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

// Alert
.Toastify__toast-body {
  @include set-font($family: $primary-font !important);
}
