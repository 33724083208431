@import "../../../../Sass/variables";

.header-section {
  @include set-margin($bottom: 25px);

  .flex-wrapper {
    @include set-flex($justify-content: space-between,
      $align-items: flex-start);

    .header-block {
      .header-title {
        @include set-flex($justify-content: flex-start, $align-items: flex-end);

        .header-title-text {
          @include set-flex($justify-content: flex-start, $align-items: center);
        }

        span {
          @include set-margin($left: 32px);
        }
      }

      .sub-title {
        @include set-dimensions($max-width: 480px);
        @include set-font($size: 14px, $weight: 400, $color: $text-primary);
      }
    }
  }

  .page-action {
    @include set-flex($justify-content: flex-start, $align-items: baseline);
  }
}