
@import "../../../../Sass/_variables.scss";



  .verify-strip--wrapper{
   @include set-box-size($width: 100%, $height: auto);
   @include set-background($color: $primary-extra-light);
   @include set-padding-all($padding: 12px);
   position: relative;
   overflow: hidden;
   .block-wrapper--flex{
    .notify-icon{
     @include set-background($color: $primary-extra-light);
     @include set-box-size($width: auto, $height: auto);
    }
    .verify-content{
     display: flex;
     @include set-flex($justify-content: center, $align-items: center);
     label{
      @include set-font($weight: 400, $size: 14px);
      margin-right: 10px;
      margin-bottom: 0;
     }
     p{
      @include set-font($weight: 500, $size: 14px);
      color: $primary-color;
      cursor: pointer;
      margin-bottom: 0;
     }
    }
   }
 }


//modal

.verify-modal-popup{
 .verify-text{
  font-family: Inter;
  font-weight: 500;
  text-align: center;
 }
}
.verify-btn{
 align-self: center!important;
 .resend-btn{
  width: 287px;
 }
}

.role-text{
 font-weight: 500;
}