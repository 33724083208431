// -----------------------------------------------------------------------------
// This file contains all default color styles
// -----------------------------------------------------------------------------
:root {
    --primary-color: #3766E8;
    --primary-color-dark: #194DDE;
    --primary-color-light: #6588EB;
}


/* Fonts Colors*/

.primary-color-dark {
    color: $primary-color-dark;
}

.primary-color {
    color: $primary-color;
}

.second-color {
    color: $second-color;
}

.off-white {
    color: $off-white;
}


.text-primary {
    color: $text-primary !important;
}

.text-secondary {
    color: $text-secondary !important;
}

.text-tertiary {
    color: $text-tertiary;
}

.heading-primary {
    color: $heading-primary;
}

.primary-color {
    color: $primary-color;
}

.primary-color-dark {
    color: $primary-color-dark;
}

.primary-color-light {
    color: $primary-color-light;
}


.black-color {
    color: $pure-black;
}

.white-color {
    color: $pure-white;
}

.text-other {
    color: $text-other;
}

.green-success-text {
    color: $green-success;
}

.red-failure-text {
    color: $red-failure;
}


.gray-color {
    color: $gray;
}

.gray-color-dark {
    color: $gray-dark;
}

.light-color {
    color: $light-color;
}


/* BG Colors */
.primary-bg {
    background-color: $primary-color;
}

.second-bg {
    background-color: $second-color;
}

.off-white-bg {
    background-color: $off-white;
}

.form-bg {
    background-color: $form-bg-color;
}

.tag-bg {
    background-color: $tag-color;
}

.pure-white-bg {
    background-color: $pure-white;
}

.video-bg {
    background-color: $video-bg;
}