.logo {
  .wobot-text-style {
    width: 160px;
    height: 36.02px;
    margin: 45px 0px;
  }
}

.cherry-img {
  .img-style {
    width: 400px;
    height: 250px;
    left: 0px;
    top: 0px;
    margin: 50px 0px;
  }
}
