// @import "./colors";
// @import "./mixins";

// -----------------------------------------------------------------------------
// This file contains all default table styles
// -----------------------------------------------------------------------------


// Table Filter
.table-filters {
    @include set-flex($justify-content: space-between, $align-items: center, $align-self: center);
    @include set-margin($bottom: 20px);

    .grouped-filter {
        @include set-flex($align-items: center);
        display: inline-flex;
        @include set-border($radius: 6px);
        @include set-padding($top: 6px, $bottom: 6px, $right: 10px, $left: 10px);
        @include set-background($color: $off-white);

        select {
            border: 1px solid $gray-dark;
        }

        .filter-btn {
            margin-bottom: 0px;

            li {
                position: relative;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border: none;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                line-height: 2;
                text-align: center;
                cursor: pointer;
                @include set-transition(all 0.3s);
                color: rgba(0, 0, 0, 0.54);
                fill: rgba(0, 0, 0, 0.54);
                background-color: transparent;

                &:hover {
                    background-color: $form-bg-color;
                }

                svg {
                    cursor: pointer;
                    width: 18px;
                    height: auto;
                }

                &.active-green {
                    svg {
                        path {
                            fill: $green-success;
                        }
                    }
                }

                &.Inactive-red {
                    svg {
                        path {
                            fill: $red-failure;
                        }
                    }
                }


            }
        }

        button,
        a {
            margin-right: 10px;
        }
    }
}

// Table
#table-content {
    header {
        @include set-dimensions($height: 10px);
        @include set-background($background: transparent);
        @include set-padding-all($padding: 0px);
        @include set-margin($bottom: 14px);
        align-items: center;

        .table-action-btn--wrapper {
            width: 100%;
            display: flex;
            max-height: 40px;
            align-items: flex-end;
            justify-content: flex-start;
            margin-bottom: 8px;
        }
    }

    .rdt_Table {
        @include set-background($color: transparent);
    }

    & .rdt_TableHeadRow {
        @include set-background($color: $pure-white);
        min-height: 50px;
        border-bottom: 4px solid $off-white;
    }

    & .rdt_TableHeader {
        background-color: transparent;
        padding: 0px;
        // min-height: 45px;
        min-height: 0px;
    }

    .rdt_TableHeader>div:nth-child(1) {
        visibility: hidden;
    }

    .rdt_TableHeader>div:nth-child(2) {
        @include set-background($color: transparent);
        padding: 0px;
        justify-content: flex-end;
    }

    .rdt_TableHeader>div:nth-child(2)>div:nth-child(1) {
        visibility: hidden;
        display: none;
    }

    .table-action-btn--wrapper {
        .action-block {
            @include set-margin($top: 0px);
        }
    }

    .eHxVgR {
        border: 0px;
        background: $off-white;
    }

    .rdt_TableHead {
        @include set-border($radius: 5px);
    }

    .rdt_TableCol {
        @include set-font($size: 14px, $weight: 500, $color: $text-other);
        text-transform: uppercase;
    }

    .rdt_TableRow {
        cursor: default !important;
        padding: 10px 0;
        border: none;
        border-bottom: 2px solid $off-white;
        background: $pure-white;

        &:hover {
            background: $off-white;
        }

        &:last-child {
            border-bottom: 4px solid $off-white;
        }
    }

    .rdt_TableRow :nth-child(2) {
        p {
            cursor: pointer !important;

            &.row-subtitle {
                cursor: default !important;
            }
        }
    }

    .rdt_TableBody {
        @include set-background($color: $pure-white);
    }

    .rdt_TableCell {

        @include set-font($size: 14px, $weight: 400, $color: $text-primary);

        .filter-btn {
            margin-bottom: 0;

            li {
                position: relative;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border: none;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                line-height: 2;
                text-align: center;
                cursor: pointer;
                @include set-transition(all 0.3s);
                color: $text-primary;
                fill: $text-primary;

                background-color: transparent;

                &:hover {
                    background-color: $form-bg-color;
                }

                svg {
                    width: 15px;
                    height: auto;
                    cursor: pointer;

                    path {
                        fill: $text-primary;
                    }
                }

                &.disabled {
                    color: $text-tertiary;
                    cursor: not-allowed;
                    pointer-events: none;

                    svg {
                        path {
                            fill: $text-tertiary;
                        }
                    }
                }
            }
        }

        .user-panel {
            .user-img {
                width: 30px;
                height: 30px;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .rdt_Pagination {
        border-top: 0px;
        min-height: 50px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background-color: $pure-white;
        color: $text-primary;

        button {
            color: $text-primary;
            fill: $text-primary;

            &:disabled {
                color: $text-tertiary;
                fill: $text-tertiary;
            }
        }
        

        .dQdisw {
            width: 30px;
            height: 30px;
            padding: 0;
            margin: 6px;
            text-align: center;
            border: 0px;
            background-color: $off-white;
            border-radius: 4px;
        }
    }

    button {
        @include set-dimensions($height: auto);
        @include set-padding($top: 8px, $bottom: 8px);

        svg path:nth-child(1) {
            fill: $text-primary;        
        }

        &:hover,
        &:focus {
            @include set-background($color: $gray);
        }
    }

    // Zero state styling
    .cTsUPQ {
        background-color: $pure-white;
        color: $text-primary;
    }
}

// Tabs filter
.filter-tabs {
    @include set-flex($justify-content: flex-end, $align-items: center, $align-self: center);
}

.filter-block {
    display: flex;
    align-items: center;
}

/* Screen Size - 592px */
@media (max-width: 592px) {
    .grouped-filter {
        form {
            justify-content: center;
        }

        button,
        a,
        .filter-btn {
            margin-bottom: 0px;
            margin-right: 10px;
        }

        select {
            width: 25%;
        }
    }
}

/* Screen Size - 425px */
@media (max-width: 425px) {
    .table-filters {
        .grouped-filter {
            justify-content: space-between;
            width: 100%;

            .filter-btn {
                margin-top: 0px;
                margin-right: 0px;
            }
        }
    }

    .table-filters {
        display: block;
    }

    .filter-btn {
        margin-top: 20px;
    }
}